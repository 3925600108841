import FrontPage from './frontPage';
import V56Login from './v56login';
import {V56Pricing} from "./v56pricing";
import { Routes, Route } from "react-router-dom";
import V56Features from "./v56features";

function App() {
  return (
    <Routes>
      <Route path="/" element={<FrontPage />} />
        <Route path="login" element={<V56Login />} />
      <Route path="pricing" element={<V56Pricing />} />
      <Route path="features" element={<V56Features />} />
    </Routes>
  );
}

export default App;
