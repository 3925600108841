import React from "react";
import { CheckIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/solid';
import V56menu from "./v56menu";

export class V56Pricing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeView: '1y',
            pricePerOneTB: 7, // Price for 1TB per month
            currency: 'eur',
            USDMultiplier: 1.1, // Multiplier for USD
            activeCss: 'relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-6',
            inactiveCss: 'ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-6',
            tiers: [
                {
                    topic: 'Pay as you go, 1TB included.',
                    name: '',
                    href: '#',
                    numberOfTerabytes: 1,
                    reservedCapacity: false,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 2,
                    discountPercentage3y: 4,
                    discountPercentage5y: 6,
                    description: 'Only pay for what you use, per TB per month',
                    includedFeatures: ['S3 compatible protocol', 'Download €0.01/GB', 'Ransomware protection', 'File versioning'],
                },
                {
                    topic: '5TB Reserved',
                    name: '5TB',
                    href: '#',
                    numberOfTerabytes: 5,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 6,
                    discountPercentage3y: 7,
                    discountPercentage5y: 8,
                    description: 'Space for personal photos and documents',
                    includedFeatures: ['S3 compatible protocol', 'Download €0.001/GB', 'Ransomware protection', 'File versioning'],
                },
                {
                    topic: '20TB Reserved',
                    name: '20TB',
                    href: '#',
                    numberOfTerabytes: 20,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 7,
                    discountPercentage3y: 10,
                    discountPercentage5y: 12,
                    description: 'Larger space for videos, photos and documents',
                    includedFeatures: [
                        'S3 compatible protocol',
                        'Download €0.001/GB', 'Ransomware protection', 'File versioning'
                    ],
                },
                {
                    topic: '50TB Reserved',
                    name: '50TB',
                    href: '#',
                    numberOfTerabytes: 50,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 8,
                    discountPercentage3y: 9,
                    discountPercentage5y: 10,
                    description: 'Space for the entire family photo and video backups',
                    includedFeatures: ['S3 compatible protocol', 'No download fees', 'Ransomware protection', 'File versioning'],
                },
                {
                    topic: '100TB Reserved',
                    name: '100TB',
                    href: '#',
                    numberOfTerabytes: 100,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 9,
                    discountPercentage3y: 10,
                    discountPercentage5y: 11,
                    description: 'Say something here',
                    includedFeatures: ['S3 compatible protocol', 'No download fees', 'Ransomware protection', 'File versioning'],
                },
                {
                    topic: '200TB Reserved',
                    name: '200TB',
                    href: '#',
                    numberOfTerabytes: 200,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 10,
                    discountPercentage3y: 11,
                    discountPercentage5y: 12,
                    description: 'Say something here',
                    includedFeatures: ['S3 compatible protocol', 'No download fees', 'Ransomware protection', 'File versioning'],
                },
                {
                    topic: '250TB Reserved',
                    name: '250TB',
                    href: '#',
                    numberOfTerabytes: 200,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 10,
                    discountPercentage3y: 11,
                    discountPercentage5y: 12,
                    description: 'Say something here',
                    includedFeatures: ['S3 compatible protocol', 'No download fees', 'Ransomware protection', 'File versioning'],
                },
                {
                    topic: '300TB Reserved',
                    name: '300TB',
                    href: '#',
                    numberOfTerabytes: 300,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 10,
                    discountPercentage3y: 11,
                    discountPercentage5y: 12,
                    description: 'Say something here',
                    includedFeatures: ['S3 compatible protocol', 'No download fees', 'Ransomware protection', 'File versioning'],
                }
                ]

        };
    }

    getMultiplier() {
        if (this.state.currency === 'eur') {
            return 1;
        } else {
            return this.state.USDMultiplier;
        }
    }

    getMonthlyPrice(tier, applyDiscount=true) {
        if (applyDiscount) {
            return (((this.state.pricePerOneTB * tier.numberOfTerabytes)*(1-(this.getDiscountPercent(tier)/100)))*this.getMultiplier()).toFixed();
        }
        return ((this.state.pricePerOneTB * tier.numberOfTerabytes)*this.getMultiplier()).toFixed();
    }

    getMonthlySavings(tier, asPercent=false) {
        if (asPercent) {
            return this.getMonthlyPrice(tier, false)-this.getMonthlyPrice(tier);
        }
        return this.getMonthlyPrice(tier, false)-this.getMonthlyPrice(tier);
    }

    getYearlySavings(tier, asPercent=false) {
        if (asPercent) {
            return this.getYearlyPrice(tier, false)-this.getYearlyPrice(tier);
        }
        return this.getYearlyPrice(tier, false)-this.getYearlyPrice(tier);
    }

    getMonthsFromPeriod() {
        switch (this.state.activeView) {
            case 'monthly':
                return 1;
            case '1y':
                return 12;
            case '3y':
                return (12*3);
            case '5y':
                return (12*5);
            default:
                return 1;
        }
    }

    getYearsText() {
        switch (this.state.activeView) {
            case 'monthly':
                return 'monthly';
            case '1y':
                return 'yearly';
            case '3y':
                return '3 years';
            case '5y':
                return '5 years';
            default:
                return 'monthly';
        }
    }

    getDiscountPercent(tier) {
        switch (this.state.activeView) {
            case 'monthly':
                return tier.discountPercentageMonthly;
            case '1y':
                return tier.discountPercentage1y;
            case '3y':
                return tier.discountPercentage3y;
            case '5y':
                return tier.discountPercentage5y;
            default:
                return tier.discountPercentageMonthly;
        }
    }

    getYearlyPrice(tier, applyDiscount=true) {
        if (applyDiscount) {
            return (((this.getMonthlyPrice(tier, false)*this.getMonthsFromPeriod())*(1-(this.getDiscountPercent(tier)/100)))*this.getMultiplier()).toFixed();
        }
        return ((this.getMonthlyPrice(tier, false)*this.getMonthsFromPeriod())*this.getMultiplier()).toFixed();
    }

    getCurrencySymbol() {
        return (this.state.currency === 'eur' ? '€' : '$');
    }

    isYearly() {
        return (this.state.activeView !== 'monthly');
    }

    getPrice(tier) {
        if (this.isYearly()) {
            return this.getYearlyPrice(tier).toString();
        } else {
            return this.getMonthlyPrice(tier).toString();
        }
    }

    getSavingsText(tier) {
        if (this.isYearly()) {
            if (this.getYearlySavings(tier) > 0.0) {
                return 'Save ' + this.getCurrencySymbol() + this.getYearlySavings(tier);
            }
        } else {
            if (this.getMonthlySavings(tier) > 0.0) {
                return 'Save ' + this.getCurrencySymbol() + this.getMonthlySavings(tier);
            }
        }

        return '';
    }

    getHidden(tier) {
        switch (this.state.activeView) {
            case 'monthly':
                return tier.reservedCapacity;
            default:
                return false;
        }
    }

    render() {
        return (
        <div className="bg-white">
            <V56menu />
            <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:flex-col sm:align-center">
                    <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
                    <p className="mt-5 text-xl text-gray-500 sm:text-center">
                        Save big on reserved capacity
                    </p>
                    <div className="relative self-center mt-8 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
                        <button
                            onClick={() => {
                                this.setState({activeView: 'monthly'});
                            }}
                            type="button"
                            className={(this.state.activeView === 'monthly' ? this.state.activeCss : this.state.inactiveCss)}
                        >
                            Pay as you go
                        </button>
                        <button
                            onClick={() => {
                                this.setState({activeView: '1y'});
                            }}
                            type="button"
                            className={(this.state.activeView === '1y' ? this.state.activeCss : this.state.inactiveCss)}
                        >
                            1 year
                        </button>
                        <button
                            onClick={() => {
                                this.setState({activeView: '3y'});
                            }}
                            type="button"
                            className={(this.state.activeView === '3y' ? this.state.activeCss : this.state.inactiveCss)}
                        >
                            3 years
                        </button>
                        <button
                            onClick={() => {
                                this.setState({activeView: '5y'});
                            }}
                            type="button"
                            className={(this.state.activeView === '5y' ? this.state.activeCss : this.state.inactiveCss)}
                        >
                            5 years
                        </button>
                        <div className="pl-4">
                            <div className="mt-1.5 relative pb-1 pr-1 ml-2">
                                <select
                                    onChange={(e) => {
                                        if (e.target.value === 'Europe (EUR)') {
                                            this.setState({currency: 'eur'});
                                        } else {
                                            this.setState({currency: 'usd'});
                                        }
                                    }}
                                    id="currency"
                                    name="currency"
                                    className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 text-base text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    defaultValue="Europe (EUR)"
                                >
                                    <option>Europe (EUR)</option>
                                    <option>United States (USD)</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                                    <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
                    {this.state.tiers.map((tier) => (
                        <div key={tier.name}
                             className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
                             hidden={this.getHidden(tier)}>
                            <div className="p-6">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.topic}</h2>
                                <p className="mt-4 text-sm text-gray-500 h-10">{tier.description}</p>
                                <p className="mt-8">
                                    <span
                                        className="text-4xl font-extrabold text-gray-900">{this.getCurrencySymbol()}{this.getPrice(tier)}</span>{' '}
                                    <span className="text-base font-medium text-gray-500">/{this.getYearsText()}</span>
                                </p>
                                <p className="mt-4 text-sm text-gray-500 h-2">{this.getSavingsText(tier)}</p>
                                <a
                                    href={tier.href}
                                    className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                >
                                    Buy {tier.name}
                                </a>
                            </div>
                            <div className="pt-6 pb-8 px-6">
                                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's
                                    included</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {tier.includedFeatures.map((feature) => (
                                        <li key={feature} className="flex space-x-3">
                                            <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500"
                                                       aria-hidden="true"/>
                                            <span className="text-sm text-gray-500">{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        );
    }
}
